import { mapState, mapWritableState } from "pinia";
import { usePageStore } from "~/pinia/platform/page.ts";
import { useFlowStore } from "~/pinia/platform/flow/flow.ts";

const defaultOptions = { disableAutoInitGraph: false };

export default (options = defaultOptions) => {
	options = { ...defaultOptions, ...options };
	return {
		async mounted() {
			const pageStore = usePageStore();
			if (options.disableAutoInitGraph || pageStore.page?.metadata?.disableFlowFetch || this.$config.public.previewMode)
				return;

			try {
				await this.initGraph();
			} catch (err) {
				this.error = err;
			}
		},
		computed: {
			...mapState(usePageStore, ["page"]),
			...mapState(useFlowStore, [
				"graph",
				"nextAction",
				"prevAction",
				"loading",
				"pathTaken"
			]),
			...mapWritableState(useFlowStore, ["error"]),
			...mapState(useFlowStore, {
				loadingFlow: "loading",
			}),
		},
		methods: {
			async initGraph() {
				try {
					await useFlowStore().init(this.page.id);
				} catch (error) {
					console.error(error);
				}
			},
			validateGraph() {
				let lastValidRoute = this.pathTaken?.[this.pathTaken?.length - 1];
				if (lastValidRoute !== useRoute().path) {
					console.log("Redirecting to last valid route", lastValidRoute);
					this.$nuxt?.context?.redirect(lastValidRoute);
				}
			},
			goBack() {
				if (this.prevAction) {
					this.$router.push(this.prevAction);
				} else {
					this.$router.push("/privat");
				}
			},
		},
	};
};
